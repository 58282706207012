export function usePagination(fn) {
  const pageTotal = ref(0);
  const pageIndex = ref(1);
  const pageSize = ref(10);
  const currentPageSizeChange = (page, pageS) => {
    pageIndex.value = page;
    pageSize.value = pageS;
    fn();
  };
  return {
    pageTotal,
    pageIndex,
    pageSize,
    currentPageSizeChange
  };
}