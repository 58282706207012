import { awaitWrap, OK } from '@/common';
import { qryExportBillTaskStatus } from '@/api/thirdParty';
import { message } from 'ant-design-vue';
export function useTimer() {
  const timer = ref(null);
  const getExportFile = async taskId => {
    const [err, data] = await awaitWrap(qryExportBillTaskStatus({
      taskId
    }));
    if (data && data.code === OK) {
      var _data$data, _data$data3;
      if (((_data$data = data.data) === null || _data$data === void 0 ? void 0 : _data$data.status) === '1') {
        var _data$data2;
        window.open(process.env.VUE_APP_DOMAIN + 'payAdminServer' + ((_data$data2 = data.data) === null || _data$data2 === void 0 ? void 0 : _data$data2.url), '_blank');
        timer.value && clearInterval(timer.value);
        timer.value = null;
      }
      if (((_data$data3 = data.data) === null || _data$data3 === void 0 ? void 0 : _data$data3.status) === '2') {
        message.error('导出失败');
        timer.value && clearInterval(timer.value);
        timer.value = null;
      }
    }
    if (err) {}
  };
  return {
    timer,
    getExportFile
  };
}