// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import request from './request'

// 通过 dbtId 查询对应的支付渠道
export const getPayChnlByDbtIds = (data: { dbtIds: string[] }) =>
  request('/payAdminServer/app/getPayChnlByDbtIds', null, 'POST_JSON', data)

// 查询三方订单列表
export const getBillList = (data: {
    "billType": string,
    "startDate": string
    "datePeriod"?: string,
    "dbtIds"?: string[],
    "dimensionList"?: string[],
    "endDate"?: string,
    "pageNum": number,
    "pageSize": number,
    "payChnls"?: string[],
}) =>
  request('/payAdminServer/bill/getBillList', null, 'POST_JSON', data)

// 导出三方账单接口
export const exportBillList = (data: {
    "billType": string,
    "startDate": string
    "datePeriod"?: string,
    "dbtIds"?: string[],
    "dimensionList"?: string[],
    "endDate"?: string,
    "payChnls"?: string[],
}) =>
  request('/payAdminServer/bill/exportBillList', null, 'POST_JSON', data)

// 查询三方订单详情
export const getBillDetails = (data: {
    "startDate": string
    "endDate": string
    "dbtIds"?: string[],
    "pageNum": number,
    "pageSize": number,
    "payChnls"?: string[],
}) =>
  request('/payAdminServer/bill/getBillDetails', null, 'POST_JSON', data)

// 导出三方订单详情
export const exportBillDetail = (data: {
    "startDate": string
    "endDate": string
    "dbtIds"?: string[],
    "payChnls"?: string[],
}) =>
  request('/payAdminServer/bill/exportBillDetail', null, 'POST_JSON', data)

// 查询导出三方订单任务详情
export const qryExportBillTaskStatus = (data: {
    taskId: string
}) =>
  request('/payAdminServer/bill/qryExportBillTaskStatus', null, 'POST_JSON', data)



// 1. 三方账单-导入账单-点击文件上传
export const postCvsUpload = (data:any) =>
request('/payAdminServer/file/upload', null, 'POST_FILE', data)

// 2. 三方账单-导入账单-导入gp账单
export const postBillAdd = (data: {
  list:{
    reqParams:{
      fileUrl:string
      accountId?:number
    }
    taskName:string
    taskType:number
  }[]
}) =>
request('/payAdminServer/bill/task/add', null, 'POST_JSON', data)

// 3. 收入看板-任务管理-查询任务列表接口
export const postBillList = (data: {
  pageNum: number
  pageSize:number
  taskStatusList:number[]
  taskTypeList:number[]
}) =>
request('/payAdminServer/bill/task/list', null, 'POST_JSON', data)

// 4. 任务管理-删除任务接口
export const postBillDel = (data: {
  id: number
}) =>
request('/payAdminServer/bill/task/delete', null, 'POST_JSON', data)

// 4. 查询账号列表
export const postNickNameList = (data: {
  accountType: number
  nickName: string
  pageNum: number
  pageSize: number
}) =>
request('/payAdminServer/account/list', null, 'POST_JSON', data)